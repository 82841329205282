import React, { useState, useCallback, useEffect } from 'react';
import cn from 'classnames';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useCrypto } from '../../hooks/crypto.hook';
import { Loader } from '../../components/Loader/Loader';
import { itemsOnPage } from '../../config/config';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { AllUsersOption } from '../../components/ReactSelect/ReactSelectOption';
import './Categories.css';

export const AllMandatoryTrainingUsers = ({
  selectedTenant, selectedDepartment, selectedLocation, users,
  selectedCategory, selectedUser, setSelectedUser
}) => {
  const { secondaryLoading, loading, requestWithSecondaryLoading, request } = useHttp();
  const { token } = useAuth();
  const { encryptData, decryptData } = useCrypto();

  const [mandatoryTrainingUsers, setMandatoryTrainingUsers] = useState({});
  const [page, setPage] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);
  const [firstFetchLoading, setFirstFetchLoading] = useState(false);

  const firstFetchMandatoryTrainingUsers = useCallback(async (
    page, selectedTenant, selectedUser, selectedDepartment, selectedLocation, selectedCategory
  ) => {
    try {
      if (token) {
        const selectedParams = {
          selectedTenant: selectedTenant?.value,
          selectedUser: selectedUser?.value,
          selectedDepartment: selectedDepartment?.label,
          selectedLocation: selectedLocation?.label,
          selectedCategory: selectedCategory?.value
        };

        const data = encryptData(selectedParams);
        setFirstFetchLoading(true);
        const fetched = await request(`/back_office/api/categories/mandatory_training_users/all/${page}`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptMandatoryTrainingUsers = decryptData(fetched);
        decryptMandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.length
          ? setMandatoryTrainingUsers(decryptMandatoryTrainingUsers)
          : setMandatoryTrainingUsers({});
        setItemsCount(decryptMandatoryTrainingUsers.allUsersListCount);
        setFirstFetchLoading(false);

        return decryptMandatoryTrainingUsers;
      }
    } catch (error) {
      setFirstFetchLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const fetchMandatoryTrainingUsers = useCallback(async (
    page, selectedTenant, selectedUser, selectedDepartment, selectedLocation, selectedCategory
  ) => {
    try {
      if (token) {
        const selectedParams = {
          selectedTenant: selectedTenant?.value,
          selectedUser: selectedUser?.label,
          selectedDepartment: selectedDepartment?.label,
          selectedLocation: selectedLocation?.label,
          selectedCategory: selectedCategory?.value
        };

        const data = encryptData(selectedParams);
        const fetched = await requestWithSecondaryLoading(`/back_office/api/categories/mandatory_training_users/all/${page + 1}`, 'POST', { data }, {
          Authorization: `Bearer ${token}`
        })

        const decryptMandatoryTrainingUsers = decryptData(fetched);
        decryptMandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.length
          ? setMandatoryTrainingUsers(prev => ({ ...prev, uniqueMandatoryTrainingUsers: [...prev.uniqueMandatoryTrainingUsers, ...decryptMandatoryTrainingUsers?.uniqueMandatoryTrainingUsers] }))
          : setMandatoryTrainingUsers(prev => prev);

        return decryptMandatoryTrainingUsers;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    firstFetchMandatoryTrainingUsers(
      1, selectedTenant, selectedUser, selectedDepartment, selectedLocation, selectedCategory
    );
  }, [
    firstFetchMandatoryTrainingUsers,
    selectedDepartment,
    selectedLocation,
    selectedTenant,
    selectedUser,
    selectedCategory
  ]);

  useEffect(() => {
    setPage(1)
  }, [selectedTenant, selectedUser, selectedDepartment, selectedLocation]);

  const handleUserChange = (data) => {
    setSelectedUser(data);
  };

  useEffect(() => {
    setSelectedUser(null);
  }, [selectedTenant, selectedDepartment, selectedLocation, selectedCategory, setSelectedUser]);

  return (
  <div className="category-main-compliance">
    <div className="category-main-description category-main-description-compliance">
      <h5 className="category-main-name">User's Details and Compliance Scores</h5>
      {!selectedUser && !loading &&
        <div className="category-main-compliance-actions">
          <p>Number of non-compliant users: <b>{mandatoryTrainingUsers?.notCompliantUsersCount || 0}</b></p>
        </div>
      }
    </div>

    <div className={cn('all-users-select-mandatory', {
      'input-container-analytics-item--disabled': loading || secondaryLoading || firstFetchLoading
      })}>
      <label className='userslist-select-label-mandatory'>
        <ReactSelect
          classNames={'multiselect-user'}
          options={users}
          optionComponent={AllUsersOption}
          value={selectedUser}
          placeholder={'User...'}
          onChange={handleUserChange}
          isSearchable={true}
          isClearable={true}
          isDisabled={loading || secondaryLoading || firstFetchLoading}
          optionHeight={50}
          rows={6} />
      </label>
    </div>

    {!firstFetchLoading
      ? !!mandatoryTrainingUsers?.uniqueMandatoryTrainingUsers?.length &&
          <div>
            <table className="category-table mandatory-users-table mandatory-all-users-table">
              <thead className="table-th-category">
                <tr className="not-clickable-category tr-grid-mandatory-users tr-category">
                  <th>
                    &#8470;
                  </th>
                  <th>
                    Name
                  </th>
                  <th>
                    Email
                  </th>
                  <th>
                    Tenant
                  </th>
                  <th>
                    Department
                  </th>
                  <th>
                    Location
                  </th>
                  <th>
                    Compliance
                  </th>
                </tr>
              </thead>
              <tbody className="table-body table-body-category">
                {mandatoryTrainingUsers?.uniqueMandatoryTrainingUsers
                  ?.sort((a, b) => a?.firstName?.localeCompare(b?.firstName))
                  ?.sort((a, b) => {
                    const viewedMandatoryTrainingEmailsA = a.viewedMandatoryTrainingEmails > 0 ? a.viewedMandatoryTrainingEmails : 0;
                    const viewedMandatoryTrainingEmailsB = b.viewedMandatoryTrainingEmails > 0 ? b.viewedMandatoryTrainingEmails : 0;
                    const complianceScoreA = !!a.allMandatoryTrainingEmails
                      ? Math.round(viewedMandatoryTrainingEmailsA / a.allMandatoryTrainingEmails * 100)
                      : 100;
                    const complianceScoreB = !!b.allMandatoryTrainingEmails
                      ? Math.round(viewedMandatoryTrainingEmailsB / b.allMandatoryTrainingEmails * 100)
                      : 100;

                    return complianceScoreA - complianceScoreB;
                  })
                  ?.map((user, index) => {
                    const viewedMandatoryTrainingEmails = user.viewedMandatoryTrainingEmails > 0 ? user.viewedMandatoryTrainingEmails : 0;
                    const allMandatoryTrainingEmails = user.allMandatoryTrainingEmails;
                    const complianceScore = allMandatoryTrainingEmails
                      ? Math.round(viewedMandatoryTrainingEmails / allMandatoryTrainingEmails * 100)
                      : 100;

                    return (
                      <tr
                        key={index}
                        className={cn("tr-grid-mandatory-users clickable-row-category tr-category")}
                      >
                        <td className={cn('list-td-category')}>{index + 1}</td>
                        <td className={cn('list-td-category')}>{user.firstName} {user.secondName}</td>
                        <td className={cn('list-td-category')}>{user.email}</td>
                        <td className={cn('list-td-category')}>{user.tenantName}</td>
                        <td className={cn('list-td-category')}>{user.department}</td>
                        <td className={cn('list-td-category')}>{user.location}</td>
                        <td className={cn('list-td-category')}>
                          <span
                            className={cn({
                              'list-td-category--low-compliance': complianceScore < 50,
                              'list-td-category--moderate-compliance': complianceScore >= 50,
                              'list-td-category--high-compliance': complianceScore > 99,
                            })}
                          >
                            {`${complianceScore}%`}
                          </span>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>

            {(page * itemsOnPage < itemsCount && !secondaryLoading && !selectedUser) &&
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <button
                  className='download-more-list-button'
                  onClick={() => {
                    setPage((prev) => prev + 1);
                    fetchMandatoryTrainingUsers(
                      page, selectedTenant, selectedUser, selectedDepartment, selectedLocation, selectedCategory
                    );
                  } }
                  type="submit"
                />
              </div>}

            {secondaryLoading &&
              <div className='mini-loader-container mini-loader-container--summary'>
                <span className="mini-loader-csv"></span>
              </div>
            }
          </div>
      : <Loader />
    }
  </div>
  )
};
