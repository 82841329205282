import React, { useState, useCallback, useEffect, useContext, useMemo, useRef } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import cn from 'classnames';
import 'react-datepicker/dist/react-datepicker.css';

import { AllUsersOption, DateRangeOption } from '../../ReactSelect/ReactSelectOption';
import { UserActivity } from '../UserActivity/UserActivity';
import { AuthContext } from '../../../context/AuthContext';
import ReactSelect from '../../ReactSelect/ReactSelect';
import { useCrypto } from '../../../hooks/crypto.hook';
import { useHttp } from '../../../hooks/http.hook';
import { CustomizedTooltipInfo } from '../../Tooltips/Tooltip';
import { dateRangeOptionsAnalytics, lastThirtyDaysChunk } from '../../../config/config';
import { ProneTable } from '../ProneTable/ProneTable';
import { Loader } from '../../Loader/Loader';
import './UserReportCard.css';

export const UserReportCard = () => {
  const { request, loading, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { token, showToastMessage } = useContext(AuthContext);
  const { encryptData, decryptData } = useCrypto();

  const refs = {
    name: useRef(null),
    department: useRef(null),
    location: useRef(null)
  };

  const [selectedUserOptions, setSelectedUserOptions] = useState([]);
  const [usersOptionList, setUsersOptionList] = useState([]);
  const [userData, setUserData] = useState({});
  const [found, setFound] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);
  const [dateRangeOptionsList, setDateRangeOptionsList] = useState(dateRangeOptionsAnalytics);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [customDateRange, setCustomDateRange] = useState(false);
  const [filteredMinMailingDate, setFilteredMinMailingDate] = useState(null);
  const [tableData, setTableData] = useState([{
    title: 'Mandatory Training Compliance',
    columns: ['Category', 'Date Enrolled', 'Date Completed', 'Compliance Status', 'Certificate'],
    data: [],
    riskStatus: true,
  }]);
  const [copiedValue, setCopiedValue] = useState('');
  const [downloadCertificateLoader, setDownloadCertificateLoader] = useState(false);

  const setDynamicTitle = (ref) => {
    if (ref.current) {
      const containerWidth = ref.current.offsetWidth;
      const textWidth = ref.current.scrollWidth;

      if (textWidth > containerWidth) {
        ref.current.setAttribute('title', ref.current.textContent);
      } else {
        ref.current.removeAttribute('title');
      }
    }
  };

  useEffect(() => {
    setDynamicTitle(refs.name);
    setDynamicTitle(refs.department);
    setDynamicTitle(refs.location);

    window.addEventListener('resize', () => {
      setDynamicTitle(refs.name);
      setDynamicTitle(refs.department);
      setDynamicTitle(refs.location);
    });

    return () => window.removeEventListener('resize', () => {
      setDynamicTitle(refs.name);
      setDynamicTitle(refs.department);
      setDynamicTitle(refs.location);
    });
  }, [refs.department, refs.location, refs.name, userData]);

  const fetchAllUsers = useCallback(async () => {
    try {
      if (token) {
        const fetched = await requestWithSecondaryLoading(`/back_office/api/analytics/all_users`, 'GET', null, {
          Authorization: `Bearer ${token}`
        })

        const decryptUsers = decryptData(fetched);

        const optionList = decryptUsers.map(user => ({
          value: user.id,
          label: user.email,
          caption: user.firstName + ' ' + user.secondName,
          minMailingDate: user.minMailingDate,
          maxMailingDate: user.maxActionsDate || user.maxMailingDate,
        }));

        setUsersOptionList(optionList);

        return decryptUsers;
      }
    } catch (error) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  useEffect(() => {
    if (!selectedUserOptions) setFound(false);
  }, [selectedUserOptions]);

  const fetchUserData = useCallback(async () => {
    try {
      if (token && selectedUserOptions?.value) {
        const data = encryptData({
          userId: selectedUserOptions?.value,
          startDate: startDate || minDate,
          endDate: endDate || maxDate,
        })
        const responseUserData = await request('/back_office/api/analytics/user_analytic', 'POST', { data }, {
          Authorization: `Bearer ${token}`
        });

        const decryptUserData = decryptData(responseUserData);

        if (decryptUserData) {
          setUserData(decryptUserData);
          setFound(true);
        }

        if (decryptUserData?.complianceStatus) setTableData(decryptUserData?.complianceTableData);

        return decryptUserData;
      } else {
        setFound(false);
        setUserData({});
        setSelectedDateRange(null);
        setCustomDateRange(false);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, selectedUserOptions?.value, startDate, endDate]);


  useEffect(() => {
    fetchUserData();
  }, [fetchUserData]);

  const filteredDateRangeOptions = useMemo(() => {
    const minDateDiff = moment().diff(moment(filteredMinMailingDate), 'days');
    return dateRangeOptionsAnalytics.filter(option =>
      (option.value <= minDateDiff) || option.value === 0 || option.value === -1
    );
  }, [filteredMinMailingDate]);

  useEffect(() => {
    setDateRangeOptionsList(filteredDateRangeOptions);
  }, [filteredDateRangeOptions, setDateRangeOptionsList]);

  const resetDateRange = useCallback(() => {
    setSelectedDateRange(dateRangeOptionsAnalytics[0]);
    setStartDate(moment().subtract(lastThirtyDaysChunk, 'days').format('YYYY-MM-DD'));
    setEndDate(moment().format('YYYY-MM-DD'));
  }, [setSelectedDateRange]);

  const handleUserSelect = (data) => {
    resetDateRange();
    const minMailingDate = data?.minMailingDate || '2000-01-01';
    const maxMailingDate = moment().format('YYYY-MM-DD');

    setFilteredMinMailingDate(minMailingDate);

    setSelectedUserOptions(data);
    setMinDate(minMailingDate);
    setMaxDate(maxMailingDate);
  };

  const handleDateRangeChange = useCallback((data) => {
    if (!data || data?.value === 0) {
      setStartDate(filteredMinMailingDate);
      setEndDate(moment().format('YYYY-MM-DD'));
      setCustomDateRange(false);
    } else if (data?.value === -1) {
      setCustomDateRange(true);
    } else {
      setStartDate(moment().subtract(data.value, 'days').format('YYYY-MM-DD'));
      setEndDate(moment().format('YYYY-MM-DD'));
      setCustomDateRange(false);
    }
    setMaxDate(moment().format('YYYY-MM-DD'));
    setSelectedDateRange(data);
  }, [filteredMinMailingDate]);

  const handleCopyClick = useCallback(async (text) => {
    try {
      if (text !== copiedValue) {
        await navigator.clipboard.writeText(text);
        showToastMessage(null, `${text} copied to the clipboard`);
        setCopiedValue(text);
      };
    } catch (error) { }
  }, [copiedValue, showToastMessage]);

  const downloadCertificateCallback = useCallback(async (row) => {
    try {
      setDownloadCertificateLoader(true);
      if (token && selectedUserOptions?.value) {
        const placeholders = {
          fullName: `${userData.firstName} ${userData.secondName}`,
          trainingName: `${row[0]} Training`,
          // trainingName: `PhishFirewall ${row[0]} Training`, // ???
          date: row[2][0],
        };
        const filename = `${userData.firstName}_${userData.secondName}_${row[0].split(' ').join('_')}_Training_Certificate.pdf`;

        const response = await fetch('/back_office/api/analytics/download_certificate', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({data: encryptData({ placeholders, filename })}),
        });

        if (!response.ok) {
          throw new Error(`Server Error: ${response.status} ${response.statusText}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error('Error downloading certificate:', error);
    } finally {
      setDownloadCertificateLoader(false);
    }
  }, [encryptData, selectedUserOptions?.value, token, userData.firstName, userData.secondName]);


  return (
    <>
      <div className={cn('user-analytics-card', {
        'user-analytics-card--loading': secondaryLoading,
        'user-analytics-card--found': selectedUserOptions?.value
      })}>
        <div className='user-analytics-container'>
          <h3>User Report Card</h3>

          <div className='user-analytics-select-container'>
            <div className={'input-container-select-analytics-item input-container-select-analytics-item--user'}>
              <ReactSelect
                options={usersOptionList}
                optionComponent={AllUsersOption}
                classNames={cn('user-select-analytics', {'user-select--disabled': secondaryLoading || loading})}
                placeholder={'Select User...'}
                value={selectedUserOptions}
                onChange={handleUserSelect}
                isSearchable={true}
                isClearable={true}
                isDisabled={secondaryLoading || loading}
                optionHeight={50}
                rows={6}
                isLoading={secondaryLoading}
              />
            </div>
            {customDateRange &&
              <div className='analytics-date-range-container'>
                <div className='input-field-history-date-range-container'>
                  <div className={cn("input-field-history", {
                    'input-container-analytics-item--disabled': loading
                  })}>
                    <DatePicker
                      selected={startDate ? moment(startDate).format() : null}
                      onChange={(date) => {
                        setStartDate(moment(date).format('YYYY-MM-DD'));
                        setSelectedDateRange({ value: -1, label: 'Custom range' });
                      }}
                      selectsStart
                      onKeyDown={(e) => e.preventDefault()}
                      minDate={filteredMinMailingDate ? moment(filteredMinMailingDate).format() : null}
                      maxDate={maxDate ? moment(maxDate).format() : null}
                      className={cn("input-edit-history", { "input-edit-history-loading": loading })}
                      disabled={loading}
                      placeholderText="Enter Date"
                    />
                  </div>
                  <p>&#11020;</p>
                  <div className={cn("input-field-history", {
                    'input-container-analytics-item--disabled': loading
                  })}>
                    <DatePicker
                      selected={endDate ? moment(endDate).format() : null}
                      onChange={(date) => {
                        setEndDate(moment(date).format('YYYY-MM-DD'));
                        setSelectedDateRange({ value: -1, label: 'Custom range' });
                      }}
                      selectsStart
                      onKeyDown={(e) => e.preventDefault()}
                      minDate={minDate ? moment(minDate).format() : null}
                      maxDate={maxDate ? moment().format() : null}
                      className={cn("input-edit-history", { "input-edit-history-loading": loading })}
                      disabled={loading}
                      placeholderText="Enter Date"
                    />
                  </div>
                </div>
              </div>
            }

            <div className={cn('input-container-select-analytics-item input-container-select-analytics-item--date-range', {
              'input-container-analytics-item--disabled': loading || !selectedUserOptions?.value || secondaryLoading
            })}>
              <ReactSelect
                options={dateRangeOptionsList}
                optionComponent={DateRangeOption}
                placeholder={'Date Range'}
                onChange={handleDateRangeChange}
                value={selectedDateRange}
                isSearchable={false}
                isDisabled={loading}
                optionHeight={28}
                rows={10}
              />
            </div>
          </div>
        </div>
      </div>

      {selectedUserOptions?.value
        ? <>
            <div className={cn('user-analytics-raw', {
              'user-analytics-raw--loading': loading
            })}>
              <div className='user-report-mini-card'>
                <div className='user-analytics-text-item-first user-analytics-text-item-first--name'>
                  <div className='user-report-text-item-title-first'>User name:</div>
                  {loading
                    ? <div className='metrics-loader-container'>
                        <span className='metrics-loader'></span>
                      </div>
                    : <div className='user-report-text-item-value-first' ref={refs.name}>
                        {userData.firstName} {userData.secondName}
                      </div>
                  }
                </div>
                <div className='user-analytics-text-item-first'>
                  <div className='user-report-text-item-title-first'>Email:</div>
                  {loading
                    ? <div className='metrics-loader-container'>
                        <span className='metrics-loader'></span>
                      </div>
                    : <div
                        className={cn('user-report-text-item-value-second', {
                          'user-report-text-item-value-second--copy': userData.email !== copiedValue
                        })}
                        title={
                          userData.email !== copiedValue
                            ? `Click to copy "${userData.email}" to the clipboard`
                            : `"${userData.email}" has been copied to the clipboard`
                        }
                        onClick={() => handleCopyClick(userData.email)}
                      >
                        {userData.email}
                      </div>
                  }
                </div>
                <div className='user-analytics-text-item-first'>
                  <div className='user-report-text-item-title-first'>Department:</div>
                  {loading
                    ? <div className='metrics-loader-container'>
                        <span className='metrics-loader'></span>
                      </div>
                    : <div className='user-report-text-item-value-second' ref={refs.department}>
                        {userData.department || '-'}
                      </div>
                  }
                </div>
                <div className='user-analytics-text-item-first'>
                  <div className='user-report-text-item-title-first'>Location:</div>
                  {loading
                    ? <div className='metrics-loader-container'>
                        <span className='metrics-loader'></span>
                      </div>
                    : <div className='user-report-text-item-value-second' ref={refs.location}>
                        {userData.location || '-'}
                      </div>
                  }
                </div>
                <div className='user-analytics-text-item-first'>
                  <div className='user-report-text-item-title-first'>Date Enrolled:</div>
                  {loading
                    ? <div className='metrics-loader-container'>
                        <span className='metrics-loader'></span>
                      </div>
                    : <div className='user-report-text-item-value-second user-report-text-item-value-second--last'>
                        {moment(userData.createdAt).format('dddd, MMM DD, YYYY')}
                      </div>
                  }
                </div>
              </div>

              <div className='analytics-metrics-user-report-card'>
                <h4>Metrics</h4>
                <div className='analytics-metrics-container-risk'>
                  <div className='analytics-metrics-item'>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className={cn('analytics-metrics-value-current', {
                              'analytics-metrics-value-current--low': userData?.riskLevel === 'Low Risk',
                              'analytics-metrics-value-current--moderate': userData?.riskLevel === 'Moderate Risk',
                              'analytics-metrics-value-current--high': userData?.riskLevel === 'High Risk',
                            })}>
                              {userData?.riskLevel?.replace(' Risk', '') || 'Low'}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Risk Level</div>
                    </div>

                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className={cn('analytics-metrics-value-current', {
                              'analytics-metrics-value-current--compliant': userData?.complianceStatus === 'Compliant',
                              'analytics-metrics-value-current--non-compliant': userData?.complianceStatus === 'Non-Compliant',
                            })}>
                              {userData?.complianceStatus || '-'}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Compliance Status</div>
                    </div>
                  </div>
                  <div className='analytics-metrics-item'>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {userData.phishClickRate}%
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffPhishClickRate < 0,
                              'analytics-metrics-value-prev--negative': userData.diffPhishClickRate > 0
                            })}>
                              {userData.diffPhishClickRate > 0 && '+'}
                              {!!userData.diffPhishClickRate && userData.diffPhishClickRate?.toString()?.replace('.00', '') + '%'}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Phish Click Rate</div>
                    </div>

                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {parseInt(userData?.totalPhishingEmailSent)?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Phishing Simulations</div>
                    </div>

                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {parseInt(userData?.phishClickCount)?.toLocaleString('en-US')}
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffPhishClickCount < 0,
                              'analytics-metrics-value-prev--negative': userData.diffPhishClickCount > 0
                            })}>
                              {userData.diffPhishClickCount > 0 && '+'}
                              {!!userData.diffPhishClickCount && userData.diffPhishClickCount?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Phish Click Count</div>
                    </div>
                  </div>

                  <div className='analytics-metrics-item'>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {userData?.engagementRate !== null ? `${userData?.engagementRate?.toString()?.replace('.00', '')}%` : '-'}
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffEngagementRate > 0,
                              'analytics-metrics-value-prev--negative': userData.diffEngagementRate < 0
                            })}>
                              {userData.diffEngagementRate > 0 && '+'}
                              {!!userData.diffEngagementRate && userData.diffEngagementRate?.toString()?.replace('.00', '') + '%'}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>
                        <div className='education-text-item-title-info'>
                          Engagement Rate:
                          <CustomizedTooltipInfo
                            text={'Engagement Rate is calculated only for full completed months.'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {parseInt(userData?.totalEducationalEmailsSent)?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Educational Emails</div>
                    </div>

                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {parseInt(userData?.educationalInteractions)?.toLocaleString('en-US')}
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffEducationalInteractions > 0,
                              'analytics-metrics-value-prev--negative': userData.diffEducationalInteractions < 0
                            })}>
                              {userData.diffEducationalInteractions > 0 && '+'}
                              {!!userData.diffEducationalInteractions && userData.diffEducationalInteractions?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Educational Interactions</div>
                    </div>
                  </div>

                  <div className='analytics-metrics-item'>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {userData?.accuracyRate || 0}%
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffAccuracyRate > 0,
                              'analytics-metrics-value-prev--negative': userData.diffAccuracyRate < 0
                            })}>
                              {userData.diffAccuracyRate > 0 && '+'}
                              {!!userData.diffAccuracyRate && userData.diffAccuracyRate?.toString()?.replace('.00', '') + '%'}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Accuracy Rate</div>
                    </div>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {parseInt(userData?.reportedPhishCount)?.toLocaleString('en-US')}
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffReportedPhishCount > 0,
                              'analytics-metrics-value-prev--negative': userData.diffReportedPhishCount < 0
                            })}>
                              {userData.diffReportedPhishCount > 0 && '+'}
                              {!!userData.diffReportedPhishCount && userData.diffReportedPhishCount?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Reported Phish Count</div>
                    </div>

                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {userData.accuratelyReportedPhish}
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffAccuratelyReportedPhish > 0,
                              'analytics-metrics-value-prev--negative': userData.diffAccuratelyReportedPhish < 0
                            })}>
                              {userData.diffAccuratelyReportedPhish > 0 && '+'}
                              {!!userData.diffAccuratelyReportedPhish && userData.diffAccuratelyReportedPhish?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Accurately Reported Phish Count</div>
                    </div>

                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {userData.inaccuratelyReportedPhish || 0}
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffInaccuratelyReportedPhish < 0,
                              'analytics-metrics-value-prev--negative': userData.diffInaccuratelyReportedPhish > 0
                            })}>
                              {userData.diffInaccuratelyReportedPhish > 0 && '+'}
                              {!!userData.diffInaccuratelyReportedPhish && userData.diffInaccuratelyReportedPhish?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Inaccurately Reported Phish Count</div>
                    </div>
                  </div>

                  <div className='analytics-metrics-item'>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {userData?.pFAccuracyRate || 0}%
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffPFAccuracyRate > 0,
                              'analytics-metrics-value-prev--negative': userData.diffPFAccuracyRate < 0
                            })}>
                              {userData.diffPFAccuracyRate > 0 && '+'}
                              {!!userData.diffPFAccuracyRate && userData.diffPFAccuracyRate?.toString()?.replace('.00', '') + '%'}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Simulated Phish Report Rate</div>
                    </div>
                    <div className='analytics-metrics-item-container'>
                      {loading
                        ? <div className='metrics-loader-container'>
                            <span className='metrics-loader'></span>
                          </div>
                        : <div className='analytics-metrics-value'>
                            <span className='analytics-metrics-value-current'>
                              {parseInt(userData?.pFReportedEmails)?.toLocaleString('en-US') || 0}
                            </span>
                            <span className={cn('analytics-metrics-value-prev', {
                              'analytics-metrics-value-prev--positive': userData.diffPFReportedEmails > 0,
                              'analytics-metrics-value-prev--negative': userData.diffPFReportedEmails < 0
                            })}>
                              {userData.diffPFReportedEmails > 0 && '+'}
                              {!!userData.diffPFReportedEmails && userData.diffPFReportedEmails?.toLocaleString('en-US')}
                            </span>
                          </div>
                      }
                      <div className='analytics-metrics-label'>Reported Simulated Phish Count</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {!loading
              ? <>
                  {!!userData?.complianceStatus &&
                    <div className="compliance-table">
                      <ProneTable
                        tablesData={tableData}
                        loading={loading}
                        downloadCertificateCallback={downloadCertificateCallback}
                        downloadCertificateLoading={downloadCertificateLoader}
                      />
                    </div>
                  }

                  <UserActivity
                    userId={selectedUserOptions?.value}
                    startDate={startDate}
                    endDate={endDate}
                  />
                </>
              : <Loader custom={true} />
            }
          </>
        : found &&
          <div className='user-analytics-card analytics-description'>
            <p>No search result found.</p>
          </div>
      }
    </>
  );
};
