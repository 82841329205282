import React, { useEffect, useState, useContext, useCallback } from 'react';
import Modal from 'react-modal';
import cn from 'classnames';
import moment from 'moment';
import { Loader } from '../../components/Loader/Loader';
import { useHttp } from '../../hooks/http.hook';
import { useAuth } from '../../hooks/auth.hook';
import { useSortableData } from '../../hooks/sort.hook';
import { getClassNamesFor } from '../../common/getClassNamesFor';
import { itemsOnPage } from '../../config/config';
import { downloadCSVFromJson } from '../../common/dovnloadCSV';
import { AuthContext } from '../../context/AuthContext';
import { languageOptions } from '../../config/config';
import Select, { components } from "react-select";
import colourStyles from '../../styles/colour-style';
// eslint-disable-next-line no-unused-vars
import azure from '../../images/icons/Azure.svg';
// eslint-disable-next-line no-unused-vars
import google from '../../images/icons/Google (colors).svg';
// eslint-disable-next-line no-unused-vars
import csv from '../../images/icons/File Red.svg';
import { useCrypto } from '../../hooks/crypto.hook';
import cross from '../../images/icons/Vector.svg';
import { AllUsersOption } from '../../components/ReactSelect/ReactSelectOption';
import ReactSelect from '../../components/ReactSelect/ReactSelect';
import { customStyles } from '../../styles/customStyles';
import './UsersList.css';
import { CustomizedTooltipDefault } from '../../components/Tooltips/Tooltip';

export const UsersList = () => {
  const { token } = useAuth();
  const { encryptData, decryptData } = useCrypto();
  const { loading, request, requestWithSecondaryLoading, secondaryLoading } = useHttp();
  const { showToastMessage } = useContext(AuthContext);

  const [allUsers, setAllUsers] = useState([]);
  const { items, requestSort, sortConfig } = useSortableData(allUsers);
  const [page, setPage] = useState(1);
  const [lang, setLang] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [deleteModal, openDeleteModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState(items);
  const [languageModal, openLanguageModal] = useState(false);
  const [editUserModal, openEditUserModal] = useState(false);
  const [selectedUsersShown, setSelectedUsersShown] = useState(false);
  const [selectedSyncType, setSyncType] = useState({ value: 'all', label: "All", id: 0, syncType: null });
  const [selectedRiskLevel, setSelectedRiskLevel] = useState(null);
  const [selectedNonEngagedType, setSelectedNonEngagedType] = useState(null);
  const [usersToDelete, setUsersToDelete] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState({ value: 'all', label: "All", id: 0, syncType: null });
  const [usersTenants, setUsersTenants] = useState([]);
  const [action, setAction] = useState({ value: 'select-action', label: "Select an Action", id: 0 });
  const [selectedUserToEdit, setSelectedUserToEdit] = useState({
    email: '',
    firstName: '',
    secondName: '',
    location: '',
    department: '',
    language: { value: '', label: '', id: '' },
  });
  const [selectedUserToEditError, setSelectedUserToEditError] = useState({
    firstName: '',
    secondName: '',
    location: '',
    department: ''
  });

  const fetchUsers = useCallback(async () => {
    try {
      if (token) {
        const response = await requestWithSecondaryLoading('/back_office/api/user/users', 'GET', null, {
          Authorization: `Bearer ${token}`
        })
        const decryptResponse = decryptData(response);

        setAllUsers(decryptResponse.map(user => ({
          ...user,
          language: getLanguage(user).language
        })));

        return decryptResponse;
      }
    } catch (error) { }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    fetchUsers()
  }, [fetchUsers]);

  const onInputChange = e => {
    const { name, value } = e.target;

    setSelectedUserToEdit(prev => ({
      ...prev,
      [name]: value.trimStart()
    }));
    validateInput(e);
  }

  const validateInput = e => {
    let { name, value } = e.target;
    setSelectedUserToEditError(prev => {
      const stateObj = { ...prev, [name]: "" };
      switch (name) {
        case "firstName":
          if (!value) {
            stateObj[name] = "Please enter user's First Name";
          } else if (!value.match(/^[\w- .]{1,50}$/)) {
            stateObj[name] = "This First Name is not valid";
          }
          break;
        case "secondName":
          if (!value) {
            stateObj[name] = "Please enter user's Last Name";
          } else if (!value.match(/^[\w- .]{1,50}$/)) {
            stateObj[name] = "This Last Name is not valid";
          }
          break;

        case "location":
          if (!value) {
            stateObj[name] = "Please enter Location";
          } else if (!value.match(/^[\w-& .,/]{1,50}$/)) {
            stateObj[name] = "This Location is not valid";
          }
          break;
        case "department":
          if (!value) {
            stateObj[name] = "Please enter Department";
          } else if (!value.match(/^[\w-& .,/]{1,50}$/)) {
            stateObj[name] = "This Department is not valid";
          }
          break;

        default:
          break;
      }
      return stateObj;
    });
  }

  const languageList = languageOptions.map((v, i) => { return { value: v?.toLowerCase(), label: v, id: i } })
  const languageOption = (props) => {
    const { label, value } = props.data;

    return (
      <components.Option {...props}>
        <div className={`actionTypes-label-${value.toString().split(' ').join('-')}`}>{label}</div>
      </components.Option>
    );
  };

  const filterUsers = (items) => items.filter(user => (
    (selectedTenant.value === 'all' ||
      (user.tenantName === selectedTenant.label && user.syncType === selectedTenant.syncType))
    && (selectedSyncType.value === 'all' || user.syncType === selectedSyncType.value)
    && (!selectedRiskLevel || user.riskScoreLevel === selectedRiskLevel.label)
    && (!selectedNonEngagedType || user[selectedNonEngagedType.value])
    && (!selectedUser || user?.email === selectedUser?.label)
  ));

  const handleActionChange = (data) => {
    setAction(data);
  };

  const handleLangChange = (data) => {
    setLang(data);
  };

  const actionsList = [
    { value: 'lang', label: "Change language", id: 1 },
    { value: 'delete', label: "Delete users (CSV synced only)", id: 2 }
  ]

  const exportMailingHistoryCsv = async () => {
    try {
      const preparedHistory = filteredUsers?.map(a => ({
        'First Name': a?.firstName,
        'Last Name': a?.secondName,
        'Email Address': a?.email,
        'Created at': a?.createdAt,
        'Sync type': a?.syncType,
        'Tenant': a?.tenantName,
        'Risk Level': a?.riskScoreLevel?.replace(' Risk', ''),
      }));

      const fileName = 'all_users_' + new Date().toISOString().split('T')[0] + '.csv';
      return downloadCSVFromJson(fileName, preparedHistory, showToastMessage, 'all users');
    } catch (error) {
      showToastMessage('Failed to export CSV file!');
    }
  };

  useEffect(() => {
    switch (action?.value) {
      case 'lang':
        openLanguageModal(true);
        break;
      case 'delete':
        setUsersToDelete(allUsers
          .filter(user => selectedRows.includes(user.id) && user.syncType === 'csv'))
        openDeleteModal(true);
        break;
      default:
        break;
    }
  }, [action, action?.value, allUsers, selectedRows])

  const closeLangModal = () => {
    openLanguageModal(false);
    setAction({ value: 'select-action', label: "Select an Action", id: 0 });
  }

  const closeDeleteModal = () => {
    openDeleteModal(false);
    setAction({ value: 'select-action', label: "Select an Action", id: 0 });
    setUsersToDelete([]);
  }

  const closeEditUserModal = () => {
    openEditUserModal(false);
    setSelectedUserToEdit({
      email: '',
      firstName: '',
      secondName: '',
      location: '',
      department: '',
      language: { value: '', label: '', id: '' },
    })
    setSelectedUserToEditError({
      firstName: '',
      secondName: '',
      location: '',
      department: ''
    })
  }

  const handleSyncTypeChange = (data) => {
    setSyncType(data);
    setSelectedRows([]);
    setSelectedTenant({ value: 'all', label: "All", id: 0, syncType: null });
    setSelectedRiskLevel(null);
    setSelectedNonEngagedType(null);
    setSelectedUser(null);
  };

  const handleTenantChange = (data) => {
    setSelectedTenant(data);
    setSelectedRows([]);
    setSelectedRiskLevel(null);
    setSelectedNonEngagedType(null);
    setSelectedUser(null);
  };


  const handleRiskLevelChange = (data) => {
    setSelectedRiskLevel(data);
    setSelectedRows([]);
    setSelectedNonEngagedType(null);
    setSelectedUser(null);
  };

  const handleNonEngagedTypeChange = (data) => {
    setSelectedNonEngagedType(data);
    setSelectedRows([]);
    setSelectedUser(null);
  };

  const handleUserChange = (data) => {
    setSelectedUser(data);
  };

  useEffect(() => {
    const tenants = allUsers
      .map(el => ({
        tenantName: el.tenantName,
        syncType: el.syncType
      }));
    const uniqueObjects = [...new Set(tenants.map(JSON.stringify))]
      .map(JSON.parse)
      .sort((a, b) => a.tenantName.localeCompare(b.tenantName))
      .filter(tenant => selectedSyncType.label !== 'All'
        ? tenant.syncType === selectedSyncType.value
        : tenant
      )
    const selectTenantArray = [{ tenantName: 'All', syncType: null }, ...uniqueObjects];
    const tenantList = selectTenantArray.map((v, i) => { return { value: v?.tenantName?.toLowerCase(), label: v?.tenantName, id: i, tenant: true, syncType: v?.syncType } });

    setUsersTenants(tenantList)
  }, [allUsers, selectedSyncType])

  const syncTypes = [{ value: "all", label: "All", id: 0 }, { value: 'azure', label: "Azure Active Directory", id: 1 }, { value: 'csv', label: "CSV", id: 2 }, { value: "google", label: "Google Workspace", id: 3 }]
  const riskLevels = [
    { value: 'low risk', label: 'Low Risk', id: 1 },
    { value: 'moderate risk', label: 'Moderate Risk', id: 2 },
    { value: 'high risk', label: 'High Risk', id: 3 }
  ].filter(({label}) => allUsers
    .filter(user => (
      (selectedTenant?.value === 'all' ||
        (user.tenantName === selectedTenant.label && user.syncType === selectedTenant?.syncType))
      && (selectedSyncType?.value === 'all' || user.syncType === selectedSyncType?.value)
     ))
     .some(({riskScoreLevel}) =>
       riskScoreLevel === label)
  );

  const nonEngagmentTypes = [
    { value: 'nonEngaged30', label: 'Non-engaged 30 days', id: 1 },
    { value: 'nonEngaged90', label: 'Non-engaged 90 days', id: 2 },
    { value: 'nonEngaged180', label: 'Non-engaged 180 days', id: 3 },
    { value: 'nonEngaged365', label: 'Non-engaged 365 days', id: 4 }
  ].filter(({value}) => allUsers
    .filter(user => (
      (selectedTenant?.value === 'all' ||
        (user.tenantName === selectedTenant?.label && user.syncType === selectedTenant?.syncType))
      && (selectedSyncType?.value === 'all' || user.syncType === selectedSyncType?.value)
      && (!selectedRiskLevel || user.riskScoreLevel === selectedRiskLevel?.label)
    ))
    .some(user => user[value])
  )

  const allUsersOptionList = allUsers
    .filter(user => (
      (selectedTenant.value === 'all' ||
        (user.tenantName === selectedTenant?.label && user.syncType === selectedTenant?.syncType))
      && (selectedSyncType?.value === 'all' || user.syncType === selectedSyncType?.value)
      && (!selectedRiskLevel || user.riskScoreLevel === selectedRiskLevel?.label)
      && (!selectedNonEngagedType || user[selectedNonEngagedType?.value])
    ))
    .map(user => (
      { value: user.id, label: user.email, caption: user.firstName + ' ' + user.secondName }
    ));

  const Option = (props) => {
    const { label, value, tenant, caption, syncType } = props.data;

    return (
      <components.Option {...props}>
        <div className={cn(
          `syncTypes-label-${value.toString().split(' ').join('-')}`,
          `syncTypes-label-${syncType}`,
          `actions-label-${value.toString().split(' ').join('-')}`,
          `risk-level-label-${value.toString().split(' ').join('-')}`,
          {
            'users-list-tenant-label': tenant,
            'non-engagement-label': value?.startsWith('nonEngaged')
          })}
        >
          {label} {syncType ? `(${syncType})` : ''}
        </div>
        <div className='caption'>{caption}</div>
      </components.Option>
    );
  };

  const updateLang = async () => {
    try {
      const res = await request('/back_office/api/user/update_language', 'POST', { language: lang?.label, usersIds: selectedRows }, {
        Authorization: `Bearer ${token}`
      })
      showToastMessage(res.error, res.message);
      setAction({ value: 'select-action', label: "Select an Action", id: 0 });
      setLang('');
      setSelectedRows([]);
      closeLangModal();
      fetchUsers();
    } catch (error) { }
  }

  const getLanguage = (user) => {
    const languageObject = user.tenant?.languageMap?.find((e) =>
      e.values.find((e) => user?.syncGroups?.includes(e))
    );

    let language;
    let origin;

    switch (true) {
      case !!user.language:
        origin = 'User language';
        language = user.language;

        break;
      case !!languageObject?.name:
        origin = 'Group language';
        language = languageObject.name;
        break;
      case !!user.tenant?.language:
        origin = 'Tenant language';
        language = user.tenant.language;
        break;
      default:
        origin = 'default';
        language = 'English';
        break;
    }
    return {
      language,
      origin
    }
  }

  const deleteUsers = async (usersToDelete) => {
    try {
      const data = encryptData(usersToDelete.map(({id}) => id));
      const res = await request('/back_office/api/user/delete_users', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      })

      showToastMessage(res.error, res.message);
      setAction({ value: 'select-action', label: "Select an Action", id: 0 });
      setSelectedRows([]);
      setUsersToDelete([]);
      closeDeleteModal();
      fetchUsers();
    } catch (error) { }
  }

  const updateUser = async (selectedUserToEdit) => {
    try {
      const data = encryptData(selectedUserToEdit);
      const res = await request('/back_office/api/user/edit_user', 'POST', { data }, {
        Authorization: `Bearer ${token}`
      });

      closeEditUserModal();
      showToastMessage(res.error, res.message);
      fetchUsers();
    } catch (error) { }
  }

  useEffect(() => {
    const users = filterUsers(items);
    setFilteredUsers(users);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTenant, selectedSyncType, selectedUser, selectedRiskLevel, selectedNonEngagedType, items]);

  return (
    <>
      {loading || secondaryLoading
        ? <Loader />
        : items.length
          ? <div className="users-main">
              <div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <p className="list-audit-desc">All Users</p>
                  <button
                    className='actions-download'
                    type="button"
                    onClick={exportMailingHistoryCsv}
                  >
                    Export csv
                  </button>
                </div>
                <div className='all-users-input'>
                  <div className={`userslist-select userslist-select-actions userslist-select-actions-${action.value}`}>
                    <label className='userslist-select-label'>
                      Action
                      <Select
                        id='select'
                        className='multiselect multiselect-all-users'
                        options={actionsList}
                        components={{ Option }}
                        placeholder="Select an Action"
                        value={action}
                        onChange={handleActionChange}
                        isSearchable={false}
                        styles={colourStyles}
                      />
                    </label>
                  </div>
                  <div className={`userslist-select userslist-select2-${selectedSyncType.value} userslist-select-sync`}>
                    <label className='userslist-select-label'>
                      Sync Method
                      <Select
                        id='select'
                        className='multiselect multiselect-all-users'
                        options={syncTypes}
                        components={{ Option }}
                        placeholder="Select sync type"
                        value={selectedSyncType}
                        onChange={handleSyncTypeChange}
                        isSearchable={false}
                        styles={colourStyles}
                      />
                    </label>
                  </div>
                  <div className={`userslist-select userslist-select-tenant userslist-field-tenant-${selectedTenant.syncType}`}>
                    <label className='userslist-select-label'>
                      Tenant
                      <Select
                        id='select'
                        className='multiselect multiselect-all-users'
                        options={usersTenants}
                        components={{ Option }}
                        placeholder="Select tenant"
                        value={selectedTenant}
                        onChange={handleTenantChange}
                        isSearchable={true}
                        styles={colourStyles}
                      />
                    </label>
                  </div>
                </div>

                <div className='all-users-input'>
                  <div className={`userslist-select userslist-select-risk-level-${selectedRiskLevel?.value?.split(' ')?.join('-')} userslist-select-risk-level`}>
                    <label className='userslist-select-label'>
                      Risk Level
                      <Select
                        id='select'
                        className='multiselect'
                        options={riskLevels}
                        components={{ Option }}
                        placeholder="Select Risk Level"
                        value={selectedRiskLevel}
                        onChange={handleRiskLevelChange}
                        isClearable={true}
                        isSearchable={false}
                        styles={colourStyles}
                      />
                    </label>
                  </div>

                  <div className={cn('userslist-select userslist-select-non-engagement', {
                    'userslist-select-non-engagement--active': selectedNonEngagedType
                  })}>
                    <label className='userslist-select-label'>
                      Training Engagement
                      <Select
                        id='select'
                        className='multiselect'
                        options={nonEngagmentTypes}
                        components={{ Option }}
                        placeholder="Select a Period..."
                        value={selectedNonEngagedType}
                        onChange={handleNonEngagedTypeChange}
                        isSearchable={false}
                        isClearable={true}
                        styles={colourStyles}
                      />
                    </label>
                  </div>

                  <div className={'all-users-select--with-label'}>
                    <label className='userslist-select-label'>
                      User
                      <ReactSelect
                        classNames={'multiselect'}
                        options={allUsersOptionList}
                        optionComponent={AllUsersOption}
                        value={selectedUser}
                        placeholder={'Select a User...'}
                        onChange={handleUserChange}
                        isSearchable={true}
                        isClearable={true}
                        optionHeight={50}
                        rows={6}
                      />
                    </label>
                  </div>
                </div>

                <div className={cn('all-users-search-container', {
                  'all-users-search-container-empty': !selectedRows.length || !selectedUsersShown
                })}>
                  <div className='all-users-selected-bar'>
                    <button
                      className={selectedUsersShown ? 'all-users-email-button-active' : 'all-users-email-button'}
                      onClick={() => setSelectedUsersShown((prev) => !prev)}
                    >
                      {selectedRows.length < 2
                        ? `${selectedRows.length} user selected`
                        : `${selectedRows.length} users selected`
                      }
                    </button>
                    {!!selectedRows.length &&
                      <button className='all-users-secondary-button' onClick={() => setSelectedRows([])}>
                        Clear all
                      </button>
                    }
                  </div>
                </div>
                {!!selectedUsersShown && (
                  <div className='all-users-emails-container'>
                    {selectedRows.map(selectedId => (
                      <div className='all-users-email-container'>
                        {allUsers.find(u => u.id === selectedId)?.email}
                        <span className='all-users-email-container-img'>
                          <img onClick={() => setSelectedRows((prev) => prev.filter(id => id !== selectedId))} src={cross} alt='delete'></img>
                        </span>
                      </div>
                    ))}
                  </div>
                )}

                <div className="audit table">
                  {filteredUsers?.length
                    ? <table className="list-users-table">
                        <thead className="table-th">
                          <tr className="list-users-not-clickable list-users-tr">
                            <th>
                              <div className="user-checkbox-action">
                                <label className="checkbox-label">
                                  <input
                                    type="checkbox"
                                    className="checkbox"
                                    checked={selectedRows.length === filteredUsers.length && filteredUsers.length !== 0}
                                    onChange={selectedRows.length === filteredUsers.length ? () => setSelectedRows([]) : () => setSelectedRows(filteredUsers.map(e => e.id))}
                                  />
                                  <span className='checkbox-span'></span>
                                </label>
                              </div>

                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('firstName')}
                                className={getClassNamesFor('firstName', sortConfig)}
                              >
                                First name
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('secondName')}
                                className={getClassNamesFor('secondName', sortConfig)}
                              >
                                Last name
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('email')}
                                className={getClassNamesFor('email', sortConfig)}
                              >
                                Email
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('tenantName')}
                                className={getClassNamesFor('tenantName', sortConfig)}
                              >
                                Tenant
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('department')}
                                className={getClassNamesFor('department', sortConfig)}
                              >
                                Department
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('language')}
                                className={getClassNamesFor('language', sortConfig)}
                              >
                                Language
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('location')}
                                className={getClassNamesFor('location', sortConfig)}
                              >
                                Location
                              </button>
                            </th>

                            {/* Temporarily deactivated, but can be useful for dynamic column additions */}
                            {/* <th>
                              <button
                                type="button"
                                onClick={() => requestSort('syncType')}
                                className={getClassNamesFor('syncType', sortConfig)}
                              >
                                Sync method
                              </button>
                            </th> */}

                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('lastTrainingInteraction')}
                                className={getClassNamesFor('lastTrainingInteraction', sortConfig)}
                              >
                                Last Training <br />
                                Interaction
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('riskScoreLevel')}
                                className={getClassNamesFor('riskScoreLevel', sortConfig)}
                              >
                                Risk Level
                              </button>
                            </th>
                            <th>
                              <button
                                type="button"
                                onClick={() => requestSort('createdAt')}
                                className={getClassNamesFor('createdAt', sortConfig)}
                              >
                                Created
                              </button>
                            </th>
                            <th className='list-th-edit'></th>
                          </tr>
                        </thead>
                        <tbody className="table-body">
                          {filteredUsers.map((u, i) => {
                            if (i < page * itemsOnPage) {
                              return (
                                <tr
                                  key={u.id}
                                  className={"all-users-table-clickable-row list-users-tr ".concat(selectedRows.includes(u.id) ? "group-selected" : "")}
                                >
                                  <td onClick={() => setSelectedRows(prevState => prevState.includes(u.id) ? prevState.filter(id => id !== u.id) : [...prevState, u.id])}>
                                    <div className="user-checkbox-action">
                                      <label className="checkbox-label">
                                        <input
                                          type="checkbox"
                                          className="checkbox"
                                          checked={selectedRows.includes(u.id)}
                                          onChange={() => setSelectedRows(prevState => prevState.includes(u.id) ? prevState.filter(id => id !== u.id) : [...prevState, u.id])}
                                        />
                                        <span className='checkbox-span'></span>
                                      </label>
                                    </div>
                                  </td>
                                  <td>{u?.firstName}</td>
                                  <td>{u?.secondName}</td>
                                  <td className='list-td-email' title={u.email}>{u.email}</td>
                                  <td className='list-td-email'>{u.tenantName}</td>
                                  <td className='list-td-email'>{u.department}</td>
                                  <td className='list-td-email'>{u.language}</td>
                                  <td className='list-td-email'>{u.location}</td>

                                  {/* Temporarily deactivated, but can be useful for dynamic column additions */}
                                  {/* <td className='list-td-email'>
                                    <span>
                                      {u.syncType && <img src={u.syncType === 'azure' ? azure : u.syncType === 'google' ? google : u.syncType === 'csv' ? csv : ''} alt='Logo' className='list-td-email-icon'></img>}
                                      {u.syncType}
                                    </span>
                                  </td> */}

                                  <td className='list-td-email'>{u.lastTrainingInteraction ? moment(u.lastTrainingInteraction).format('MMM DD, YYYY') : '-'}</td>
                                  <td className={cn('list-td-email list-td-risk-level', {
                                    'list-td-risk-level--low': u.riskScoreLevel === 'Low Risk',
                                    'list-td-risk-level--moderate': u.riskScoreLevel === 'Moderate Risk',
                                    'list-td-risk-level--high': u.riskScoreLevel === 'High Risk',
                                  })}>
                                    {u.riskScoreLevel?.replace(' Risk', '')}
                                  </td>
                                  <td className='list-td-email'>{moment(u.createdAt).format('MMM DD, YYYY')}</td>
                                  <td className='list-td-edit'>
                                    <CustomizedTooltipDefault
                                      placement='top'
                                      text='Edit user'
                                      component={
                                        <i
                                          role='button'
                                          onClick={() => {
                                            setSelectedUserToEdit({
                                              email: u.email,
                                              firstName: u.firstName,
                                              secondName: u.secondName,
                                              location: u.location,
                                              department: u.department,
                                              language: { value: u.language, label: u.language, id: u.language },
                                              syncType: u.syncType
                                            })
                                            openEditUserModal(true)
                                          }}
                                        />
                                      }
                                    />
                                  </td>
                                </tr>
                              )
                            }
                            return null;
                          })}
                        </tbody>
                      </table>
                    : <p className="actions-description">
                        No users for the specified filters.
                      </p>
                  }

                  {page * itemsOnPage < filteredUsers.length && <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <button
                      className='download-more-list-button'
                      onClick={() => {
                        setPage((prev) => prev + 1)
                      }}
                      disabled={loading}
                      type="submit"
                    />
                  </div>
                  }
                </div>
              </div>
            </div>
          : <div className="users-main">
              <p className="list-audit-desc">All Users</p>
              <p className="actions-description">
                There are no users yet.
              </p>
            </div>
      }
      <div>
        <Modal
          isOpen={languageModal}
          onRequestClose={closeLangModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form className="form-lang">
            <div style={{ marginBottom: "12px", fontSize: '20px', textAlign: 'left' }}>
              {!selectedRows.length
                ? <>
                  <span className="no-users-card-subtitle">
                    No users are selected
                  </span>

                  <button
                    className='group-button-secondary no-users-button-secondary'
                    onClick={closeLangModal}
                  >
                    Cancel
                  </button>
                </>
                : <>
                  <span className="card-title" style={{ fontSize: '20px', color: '#343232' }}>Change phish email language</span> <br></br>
                  <span className="card-subtitle">
                    Please select specific phish email language for selected users
                  </span>

                  <div className="card-content" style={{ width: '100%', padding: '0 8px' }}>
                    <div className='userslang-select' style={{ width: '100%' }}>
                      <Select
                        id='select'
                        className='multiselect multiselect-users'
                        options={languageList}
                        components={{ languageOption }}
                        placeholder="Select language"
                        value={lang}
                        onChange={handleLangChange}
                        isSearchable={true}
                        styles={colourStyles}
                        isDisabled={!selectedRows.length}
                      />
                    </div>
                  </div>
                  <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
                    <button
                      className='group-button group-button-simple'
                      onClick={() => updateLang(lang, selectedRows)}
                      disabled={!selectedRows.length || !lang}
                      type="submit"
                    >
                      Save changes
                    </button>

                    <button
                      className='group-button-secondary'
                      onClick={closeLangModal}
                    >
                      Cancel
                    </button>
                  </div>
                </>
              }
            </div>
          </form>
        </Modal>

        <Modal
          isOpen={deleteModal}
          onRequestClose={closeDeleteModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form className="form-delete">
            <div className="content-delete">
              {!!usersToDelete.length
                ? <>
                  <span className="card-title">Permanently delete users</span> <br></br>
                  <div>
                    <span className="card-subtitle">
                      Do you really want to delete {usersToDelete.length > 1 ? 'these users' : 'this user'}?
                    </span>

                    <div className='delete-scroll'>
                      {usersToDelete.map(({id}, i) => {
                          const user = allUsers.find(user => user.id === id);

                          return (
                            <div>{i + 1}. {user.firstName} {user.secondName} <strong>{'<'}{user.email}{'>'}</strong></div>
                          )
                        })
                      }
                    </div>
                  </div>
                </>
                : <span className="card-subtitle">
                  No users with CSV sync method are selected
                </span>
              }
            </div>

            {usersToDelete.length
              ? <div className="all-users-card-action-modal-remove">
                <button
                  type='button'
                  onClick={() => deleteUsers(usersToDelete)}
                  disabled={!usersToDelete.length}
                >
                  Yes, delete!
                </button>
                <button
                  type='button'
                  onClick={closeDeleteModal}
                >
                  No, I'll think about it
                </button>
              </div>
              : <div className="all-users-card-action-modal-remove">
                <button
                  type='button'
                  onClick={closeDeleteModal}
                >
                  Close
                </button>
              </div>
            }
          </form>
        </Modal>

        <Modal
          isOpen={editUserModal}
          onRequestClose={closeEditUserModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <form className="form-delete">
            <div className="content-delete">
              <span className="card-title">Edit User</span> <br></br>
              <div className="card-content">
                <div className="input-field">
                  <label
                    htmlFor="firstName"
                    className="input-label input-label-required"
                  >
                    First Name
                  </label>
                  <CustomizedTooltipDefault
                    placement='top'
                    text={selectedUserToEdit.syncType !== 'csv'
                      ? 'First Name cannot be changed for non-CSV synced users'
                      : null
                    }
                    component={
                      <input
                        id="firstName"
                        type="text"
                        name="firstName"
                        placeholder='First name...'
                        className={cn("input", "validate", { "err-input": selectedUserToEditError.firstName })}
                        value={selectedUserToEdit.firstName}
                        onChange={onInputChange}
                        onBlur={validateInput}
                        disabled={selectedUserToEdit.syncType !== 'csv'}
                      />
                    }
                  />
                  {selectedUserToEditError.firstName && <span className='err'>{selectedUserToEditError.firstName}</span>}
                </div>

                <div className="input-field">
                  <label
                    htmlFor="secondName"
                    className="input-label input-label-required"
                  >
                    Last Name
                  </label>
                  <CustomizedTooltipDefault
                    placement='top'
                    text={selectedUserToEdit.syncType !== 'csv'
                      ? 'Last Name cannot be changed for non-CSV synced users'
                      : null
                    }
                    component={
                      <input
                        id="secondName"
                        type="text"
                        name="secondName"
                        placeholder='Last name...'
                        className={cn("input", "validate", { "err-input": selectedUserToEditError.secondName })}
                        value={selectedUserToEdit.secondName}
                        onChange={onInputChange}
                        onBlur={validateInput}
                        disabled={selectedUserToEdit.syncType !== 'csv' }
                      />
                    }
                  />
                  {selectedUserToEditError.secondName && <span className='err'>{selectedUserToEditError.secondName}</span>}
                </div>

                <div className="input-field">
                  <label
                    htmlFor="location"
                    className="input-label input-label-required"
                  >
                    Location
                  </label>
                  <CustomizedTooltipDefault
                    placement='top'
                    text={selectedUserToEdit.syncType !== 'csv'
                      ? 'Location cannot be changed for non-CSV synced users'
                      : null
                    }
                    component={
                      <input
                        id="location"
                        type="text"
                        name="location"
                        placeholder='Location...'
                        className={cn("input", "validate", { "err-input": selectedUserToEditError.location })}
                        value={selectedUserToEdit.location}
                        onChange={onInputChange}
                        onBlur={validateInput}
                        disabled={selectedUserToEdit.syncType !== 'csv' }
                      />
                    }
                  />
                  {selectedUserToEditError.location && <span className='err'>{selectedUserToEditError.location}</span>}
                </div>

                <div className="input-field">
                  <label
                    htmlFor="department"
                    className="input-label input-label-required"
                  >
                    Department
                  </label>
                  <CustomizedTooltipDefault
                    placement='top'
                    text={selectedUserToEdit.syncType !== 'csv'
                      ? 'Department cannot be changed for non-CSV synced users'
                      : null
                    }
                    component={
                      <input
                        id="department"
                        type="text"
                        name="department"
                        placeholder='Department...'
                        className={cn("input", "validate", { "err-input": selectedUserToEditError.department })}
                        value={selectedUserToEdit.department}
                        onChange={onInputChange}
                        onBlur={validateInput}
                        disabled={selectedUserToEdit.syncType !== 'csv' }
                      />
                    }
                  />
                  {selectedUserToEditError.location && <span className='err'>{selectedUserToEditError.department}</span>}
                </div>

                <div className="input-field">
                  <label
                    htmlFor="language"
                    className="input-label input-label-required"
                  >
                    Language
                  </label>
                  <Select
                    id='language'
                    name="language"
                    options={languageList}
                    components={{ languageOption }}
                    placeholder="Select language"
                    className='input-field--language'
                    classNamePrefix="react-select"
                    value={selectedUserToEdit.language}
                    onChange={(newValue) => setSelectedUserToEdit(prev => ({ ...prev, language: {
                      value: newValue.value,
                      label: newValue.label,
                      id: newValue.id
                    }}))}
                    isSearchable={true}
                    styles={colourStyles}
                  />

                </div>
              </div>
            </div>
            <div className="all-users-card-action-modal-remove">
              <button
                type='button'
                onClick={() => updateUser(selectedUserToEdit)}
                disabled={selectedUserToEditError.firstName || selectedUserToEditError.secondName || loading}
              >
                Save
              </button>
              <button
                type='button'
                onClick={closeEditUserModal}
              >
                Cancel
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </>
  )
}
